.caption {
  text-align: center;
  margin: 1.2rem auto;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
  text-align: center;
}

.header-cell {
  padding: 0.2rem;
  font-weight: bold;

  background: #e8d7d7;
  color: var(--tg-theme-bg-color);
}

.cell {
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 1fr;
  align-items: center;
  margin: 0.25rem;
  padding: 0.6rem;
  border-radius: 0.925rem;
  border: 3px solid #e8d7d7;
}

.place {
  font-size: 2rem;
}
