/* Boxes */

.box {
  margin: 0.25rem;
  padding: 0.25rem;
  border-radius: 0.4rem;
  border: 3px solid #fcdcdc;
  background-color: #fcdcdc;
  color:#fff;
  height: fit-content;
}

.box a {
  text-decoration: none;
}

.box-dark {
  border: 3px solid #000;
  background-color: #000;
}

.box-transparent {
  border: 3px solid #eedcdc74;
  background-color: #eedcdc74;
  color: #000
}