.block {
  margin: 0.5rem;
  padding-top: 0.5rem;
  border-top: 3px solid #e8d7d7;
}

.caption {
  font-weight: 700;
}

.block pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: large;
}

.collapser {
  margin-top: 0.65rem;
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  color: #988787;
}
