.task {
  margin: 0.25rem;
  padding: 0.6rem;
  border-radius: 0.925rem;
  border: 3px solid #e8d7d7;
}

.title {
  margin: 0.25rem;
  padding: 0.5rem;
  border-bottom: 3px solid #e8d7d7;
}

.interactions {
  display: flex;
}
