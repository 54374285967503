.header {
  width: 100%;
  height: 3.125rem;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

.username {
  margin-left: auto;
  padding: 0.4rem;
  border-radius: 0.75rem;
  background-color: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
}
