.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0.5rem;
  font-weight: bold;
  font-size: large;
  width: 70%;
  cursor: pointer;
}

.interactions {
  display: flex;
}
