.panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.25rem;
  overflow-y: scroll;
  height: 100vh;
}

h1, h2, h3, h4 {
  text-align: center;
}
