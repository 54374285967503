.button {
  margin: auto 0;
  padding: 0.5rem 0.75rem;
  background: var(--tg-theme-button-color);
  color: var(--tg-theme-button-text-color);
  border: 3px solid var(--tg-theme-button-color);
  outline: none;
  cursor: pointer;
  border-radius: 0.75rem;
}

.submit {
  color: #000;
  background-color: #4bd24b;
  border-color: #4bd24b;
}

.abort {
  color: #fff;
  background-color: #d8402f;
  border-color: #d8402f;
}

.button:hover { 
  color: #000;
  background-color: #fff;
  border: 3px solid #000;
}
