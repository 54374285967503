.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid var(--tg-theme-button-color);
}

.avatar img {
  display: block;
}
