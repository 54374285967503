.wrapper {
  margin-top: 0.8rem;
  padding: 0.15rem 0.25rem;
  border: 3px solid #e8d7d7;
  border-radius: 0.75rem;
  background-color: #fff;
  color: #000;
  cursor: default;
  width: 100%;
}
