@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

.App {
  height: 100vh;
}