.form {
  margin: 0.5rem;
  padding: 0.75rem;
  border: 3px dashed #000;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
}

.form :first-child {
  margin: 0.5rem 0;
}

.form-actions {
  display: flex;
  justify-content: space-around;
}

.error {
  color: #ea3030;
  font-size: small;
}
